import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/client/text/index.vue")
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/client/text/index.vue")
  },
  {
    path: "/",
    component: () => import("../views/client"),
    children: [
      {
        path: "/",
        component: () => import("../views/client/home"),
      },
      {
        path: "/showcases",
        component: () => import("../views/client/showcases"),
      },
      {
        path: "/showcases/:id",
        component: () => import("../views/client/showcases/content.vue"),
      },
      {
        path: "/videos",
        component: () => import("../views/client/videos"),
      },
      {
        path: "/videos/:id",
        component: () => import("../views/client/videos/content.vue"),
      },
      {
        path: "/user",
        component: () => import("../views/client/user/index.vue"),
      },
      {
        path: "/login",
        component: () => import("../views/client/user/logIn.vue"),
      },
      {
        path: "/signin",
        component: () => import("../views/client/user/signIn.vue"),
      },
      {
        path: "/provider/:id",
        component: () => import("@/views/client/provider/content.vue"),
      },
      {
        path: "/provider/edit/:id",
        component: () => import("@/views/client/provider/create.vue"),
      },
      {
        path: "/provider",
        component: () => import("@/views/client/provider"),
      },
      {
        path: "/create-provider",
        component: () => import("@/views/client/provider/create.vue"),
      },
      {
        path: "/about",
        component: () => import("@/views/client/about"),
      },
      {
        path: "/search",
        component: () => import("@/views/client/search"),
      },
      {
        path: "/contact",
        component: () => import("@/views/client/contact"),
      },
    ],
  },

  {
    path: "/admin",
    component: () => import("../views/app"),
    children: [
      {
        path: "adminuser",
        component: () => import("@/views/app/admin/index.vue"),
      },
      {
        path: "static",
        component: () => import("@/views/app/static/index.vue"),
      },
      {
        path: "provider",
        component: () => import("@/views/app/provider/list.vue"),
      },
      {
        path: "privacy",
        component: () => import("@/views/app/updateText"),
      },
      {
        path: "service",
        component: () => import("@/views/app/updateText"),
      },
      {
        path: "about",
        component: () => import("@/views/app/about"),
      },
      {
        path: "audit",
        component: () => import("@/views/app/provider/list.vue"),
        props: (route) => ({ queryType: 0 }),
      },
      {
        path: "provider/content/:id",
        component: () => import("@/views/app/provider/content.vue"),
      },
      {
        path: "provider/edit/:id",
        name: "provider",
        component: () => import("@/views/app/provider/edit.vue"),
      },
      {
        path: "user",
        component: () => import("@/views/app/user/list.vue"),
      },
      {
        path: "messages",
        component: () => import("@/views/app/message"),
      },
      {
        path: "user/content/:name",
        name: "user",
        component: () => import("@/views/app/user/content.vue"),
      },
      {
        path: "showcase",
        redirect: "showcase/banner",
        children: [
          {
            path: "banner",
            name: "banner",
            component: () => import("@/views/app/banner/list.vue"),
          },
          {
            path: "banner/content/:id",
            name: "banner content",
            component: () => import("@/views/app/banner/content.vue"),
          },
          {
            path: "banner/content",
            name: "Banner信息",
            component: () => import("@/views/app/banner/content.vue"),
          },
          {
            path: "column-windows",
            name: "一级橱窗列表",
            component: () => import("@/views/app/showcase/list.vue"),
            props: (route) => ({ queryType: 0 }),
          },
          {
            path: "column-windows/content/:id",
            name: "一级橱窗信息",
            component: () => import("@/views/app/showcase/content.vue"),
            props: (route) => ({ queryType: 0 }),
          },
          {
            path: "column-windows/content",
            name: "一级橱窗",
            component: () => import("@/views/app/showcase/content.vue"),
            props: (route) => ({ queryType: 0 }),
          },
          {
            path: "grid-windows",
            name: "通用橱窗列表",
            component: () => import("@/views/app/showcase/list.vue"),
            props: (route) => ({ queryType: 1 }),
          },
          {
            path: "grid-windows/content/:id",
            name: "通用橱窗信息",
            component: () => import("@/views/app/showcase/content.vue"),
            props: (route) => ({ queryType: 1 }),
          },
          {
            path: "grid-windows/content",
            name: "通用橱窗",
            component: () => import("@/views/app/showcase/content.vue"),
            props: (route) => ({ queryType: 1 }),
          },
          {
            path: "videogroup",
            name: "视频列表",
            component: () => import("@/views/app/video/videoList.vue"),
          },
          {
            path: "videogroup/content",
            component: () => import("@/views/app/video/content.vue"),
          },
          {
            path: "videogroup/content/:id",
            component: () => import("@/views/app/video/content.vue"),
          },
        ],
      },
      {
        path: "category",
        component: () => import("@/views/app/category/list.vue"),
      },
      {
        path: "category/content",
        component: () => import("@/views/app/category/content.vue"),
      },
      {
        path: "category/content/:id",
        component: () => import("@/views/app/category/content.vue"),
      },
    ],
  },
  {
    path: "/admin/login",
    name: "login",
    component: () => import("@/views/app/session/login/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
