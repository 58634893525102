import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import "./assets/icons/index"
import 'vue3-easy-data-table/dist/style.css';
import SvgIcon from './components/SvgIcon/index.vue';

const app = createApp(App)
app.component('EasyDataTable', Vue3EasyDataTable);
app.component('svg-icon', SvgIcon);

app.use(vuetify).use(store).use(router).mount("#app");

